export const translateTypeOfService = (service) => {
    const services = {
        SCHEDULING: 'Agendamento',
        FIRST_TIME: '1ª Vez',
        SCHEDULING_FORECAST: 'Previsão de Agendamento',
        GENERAL_RETURN: 'Retorno Geral',
        RETURN_OF_REAL_STATE: 'Retorno de Imobiliária',
        BROKER_RETURN: 'Retorno do Corretor',
        CALL: 'Ligação',
        SOCIAL_MEDIA: 'Redes Sociais',
        OWN_CAPTURE: 'Captação Própria'
    }

    return services[service]
}