import HtmlParser from "react-html-parser"
import { Card, CardBody, CardHeader } from "reactstrap"
import { translateTypeOfService } from "variables/translateTypeOfService"

export const CardTimeLine = ({ content, isActivity }) => {

    const translateType = (type) => {
        const services = {
            Scheduling: 'Agendamento',
            Call: 'Ligação',
            "E-mail": 'Email'
        }
    
        return services[type] || translateTypeOfService(type)
    }

    return (
        <>
            {
                content !== null && !isActivity && (
                    <Card style={{ padding: 20 }}>
                        <CardHeader>
                            <Card
                                style={{
                                    backgroundColor: '#D84FD3',
                                    color: 'white',
                                    width: 130,
                                    padding: 10,
                                    textAlign: 'center',
                                    borderRadius: 30,
                                }}
                            >
                                Nova nota
                            </Card>
                        </CardHeader>
                        <CardBody
                            style={{
                                color: 'white',
                                fontSize: 17,

                            }}
                        >
                            <p>{HtmlParser(content.text)}</p>
                            {content.document && (<a href={content.document} download="file">Arquivo: {content.document}</a>)}
                            <p>{new Date(content.updatedAt).toLocaleDateString("pt-BR")} {new Date(content.updatedAt).toLocaleTimeString("pt-BR")}</p>
                        </CardBody>
                    </Card>
                )
            }
            {
                content !== null && isActivity && (
                    <Card style={{ padding: 20 }}>
                        <CardHeader>
                            <Card
                                style={{
                                    backgroundColor: '#D84FD3',
                                    color: 'white',
                                    width: 130,
                                    padding: 10,
                                    textAlign: 'center',
                                    borderRadius: 30,
                                }}
                            >
                                Nova nota
                            </Card>
                        </CardHeader>
                        <CardBody
                            style={{
                                color: 'white',
                                fontSize: 17,

                            }}
                        >
                            <p>{`${content.createdBy?.name} agendou uma nova atividade ao cliente "${content.client?.name}".`}</p>
                            <p>Data e Hora: {content.scheduledAt}</p>
                            <p>Tipo: {translateType(content.type)}</p>
                            <p style={{ display: 'flex', gap: 8 }}>
                                Descrição:
                                <p>{HtmlParser(content.description)}</p>
                            </p>
                        </CardBody>
                    </Card>
                )
            }
        </>
    )
}